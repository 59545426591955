import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
// import { Box, Flex, Text } from 'theme-ui'

import Seo from '../components/shared/seo'
import Layout from '../components/shared/layout'
import Hero from '../components/shared/hero'
import ArtistsPreview from '../components/artists-preview'
import { Box } from 'theme-ui'

class ArtistsIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulArtistPage.nodes')

    return (
      <Layout location={this.props.location}>
        <Seo title="Artists" />
        <Hero title="Artists" />
        <Box as="section" sx={{m: 'auto', maxWidth: '50rem'}}>
          <ArtistsPreview posts={posts} />
        </Box>
      </Layout>
    )
  }
}

export default ArtistsIndex

export const pageQuery = graphql`
  query ArtistsIndexQuery {
    allContentfulArtistPage(sort: { fields: [publishDate], order: DESC }) {
      nodes {
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        tags
        heroImage {
          description
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: WEBP
            width: 424
            height: 239
          )
        }
        description {
          description
        }
      }
    }
  }
`
