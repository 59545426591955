/** @jsx jsx */
import { jsx } from 'theme-ui'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Grid, Text, Heading } from '@theme-ui/components'
import InternalLink from './shared/internal-link'

const ArtistsPreview = ({ posts }) => {
  if (!posts) return null
  if (!Array.isArray(posts)) return null
  return (
    <Grid
      p={4}
      gap={[20, null, 32]}
      columns={[`repeat(auto-fit, minmax(280px, 1fr))`]}
    >
      {posts.sort((a, b) => a.title.localeCompare(b.title)).map((post) => {
        return (
          <InternalLink
            key={post.slug}
            // as="article"
            to={`/artists/${post.slug}`}
            sx={{
              borderBottom: 'none',
              transition: 'all 0.15s ease-in-out',
              '&:hover': {
                h2: {
                  color: 'primary',
                  transition: 'all 0.3s ease-in-out',
                },
                scale: '1.05',

              },
            }}
          >
            <GatsbyImage
              alt={post.heroImage.description}
              image={post.heroImage.gatsbyImageData}
            />
            <Heading
              variant="styles.h3"
              mt={2}
              mb={'1px'}
              sx={{
                display: 'inline-block',
                // color: 'secondary',
              }}
            >
              {post.title}
            </Heading>
            <Text as="p" sx={{ opacity: '0.6', mb: 1 }}>
              {post.description.description}
            </Text>
          </InternalLink>
        )
      })}
    </Grid>
  )
}

export default ArtistsPreview
